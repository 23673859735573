import { OrgCoupon, OrgPaymentPlan } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";

export async function orgPaymentPlan__client__addOrgPaymentPlan(p: {
  orgPaymentPlan: DistributiveOmit<OrgPaymentPlan, "id" | "createdAtMS">;
  locale: string;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const newOrgPaymentPlan: OrgPaymentPlan = { ...p.orgPaymentPlan, id: h.OrgPaymentPlan.generateId(), createdAtMS: Date.now() };

  const newPlan = _.cloneDeep(newOrgPaymentPlan);

  await h.OrgPaymentPlan.add({
    doc: {
      ...newPlan,
      numberOfDaysUntilLate: newPlan.numberOfDaysUntilLate ?? 0
    }
  });
}

// i18n certified - complete
