import { OrgPaymentPlan } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function orgPaymentPlan__client__updateOrgPaymentPlan(p: { orgPaymentPlan: OrgPaymentPlan }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const updatedPlan = _.cloneDeep(p.orgPaymentPlan);
  await h.OrgPaymentPlan.set({
    id: p.orgPaymentPlan.id,
    doc: {
      ...updatedPlan,
      numberOfDaysUntilLate: updatedPlan.numberOfDaysUntilLate ?? 0
    }
  });
}

// i18n certified - complete
