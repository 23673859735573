import { OrgPaymentPlanId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import _ from "lodash";

export async function orgPaymentPlan__server__getOrgPaymentPlans(p: { orgPaymentPlanIds: OrgPaymentPlanId[] }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  return _.compact(await h.OrgPaymentPlan.getDocs(p.orgPaymentPlanIds));
}

orgPaymentPlan__server__getOrgPaymentPlans.auth = async (r: express.Request) => {
  await validateToken(r);
};
