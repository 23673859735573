import { OrgId, OrgPaymentPlanId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import _ from "lodash";

export async function orgPaymentPlan__server__getOrgPaymentPlansForOrgNoAuth(p: { orgId: OrgId }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();

  return (await h.OrgPaymentPlan.query({ where: [{ orgId: ["==", p.orgId] }] })).docs;
}

orgPaymentPlan__server__getOrgPaymentPlansForOrgNoAuth.auth = async (r: express.Request) => {};
