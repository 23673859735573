import { OrgCoupon, OrgPaymentPlan, OrgPaymentPlanId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";

export async function orgPaymentPlan__client__getOrgPaymentPlan(p: { orgPaymentPlanId: OrgPaymentPlanId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return await h.OrgPaymentPlan.getDoc(p.orgPaymentPlanId);
}

// i18n certified - complete
